/*jshint esversion: 6 */

import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import videojs from 'video.js';
import moment from 'moment-timezone';
//import url from '../assets/ec-bg-169.jpg';
import 'videojs-errors';

require('videojs-hotkeys')
//require('videojs-ga');

// register videojs-wavesurfer plugin
//import wavesurfer_css from 'videojs-wavesurfer/dist/css/videojs.wavesurfer.css';
//import Wavesurfer from 'videojs-wavesurfer/dist/videojs.wavesurfer.js';
//import mp4 from '../assets/audio_only.mp4';

import '../styles/main.css';
import '@videojs/themes/dist/sea/index.css';
import translations from './translations'
import languages from './languages'
import 'videojs-flash';
import {
    CaptionParser
} from 'mux.js/lib/mp4';
import mp4probe from 'mux.js/lib/mp4/probe';
import {
    stubFalse
} from 'lodash';


export class smvplayer {

    constructor() {
        moment.tz.setDefault(Intl.DateTimeFormat().resolvedOptions().timeZone);
        //TEMP VARIABLES 
        this.application = "";
        this.audioLang = "or";
        this.audioLangs = [];
        this.quality = "";
        this.qualityLevels = [];
        this.autostart = false;
        this.backgroundUrl = "";
        this.buffering = false;
        this.callbacks = {};
        this.clientIp = "";
        this.engine = "";
        this.lastMessage = false;
        this.lastPlayedPos = 1;
        this.pauseAfterReload = false;
        this.activePlayers = [];
        this.m = [
            "android_hlsjs",
            "android_html5",
            "android_rtsp",
            "ios",
            "osx_unicast_flash",
            "osx_unicast_html",
            "osx_unicast_rtmp",
            "windows_unicast_flash",
            "windows_unicast_html",
            "windows_unicast_rtmp",
            "windows_multicast",
            "windowsphone",
            "linux_unicast_flash",
            "linux_unicast_html",
            "linux_unicast_rtmp",
            "linux_multicast",
            "playstation",
            "xbox",
            "other"
        ];

        this.mode = "";
        this.multicast = false;
        this.ports = {};
        this.sInitMediaUrl = false;
        this.server = "";
        this.status = [];
        this.skin = "";
        this.streamType = null;
        this.skins = [
            'default.css', // Default (Light Blue)
            'manly.css', // The Manly Skin! (Pink!)
            'dbv.css', // DBVideo Skin (Red)
            'nnhq.css', // NNHQ (Dark Blue)
            'standard.css', // Standard Skin (Black 'n' White)
            'cms.css', // CMS styled skin.
            'ec.css', // European Commission Skin (Blue-ish)
            'greens.css', // GreensEFA Player Skin (Greenish)
            'antwerp.css', // Stad Antwerpuh (Zet die ploat af)
            'ep.css', // EP Player3 Skin.
            'demo.css',
            'epp.css',
            'guengl.css',
            'hmsk.css',
            'smv.css'
        ];

        this.networkError = false;
        this.showDisclaimer = false;
        this.timeline = {
            currentItem: 0,
            playlist: [{
                source: {}
            }],
            finished: false
        };
        this.timelineSync = false;
        this.uiLabels = {};
        this.userMediaUrl = false;
        this.unicastFallback = false;
        this.unicastfallbacktimeout = 0;
        this.registeredCallbacks = {};
        this.playlist = {}
        this.progressInterval = null
        this.playPromises = []
        this.fullyPlayed = false
        this.seekSteps = 5
        this.translations = new translations()
        this.languages = new languages()
        //END TEMP VARS

        this.settings = {};
        this.keyboardPresses = []
        this.playBtn;
        this.volumeBtn;
        this.playbackRateBtn;
        this.progressControlBtn;
        this.fullScreenBtn;
        this.subsBtn;
        this.liveTranscriptions = []
    }

    initTemp(playerIds) {
        this.ids = playerIds;
        this.playerIds = playerIds;

        this.createInstance(playerIds);

        let obj = {
            'low': {
                de: 'stuff',
                en: 'en stuff',
                or: 'other stuff'
            }
        }

        var result = Object.keys(obj)

        playerIds.forEach(id => {
            this.player = videojs(id, {
                controlBar: {
                    'pictureInPictureToggle': false
                }
            });
        });

        if (typeof mp4 !== 'undefined') {
            for (var i = 0; i < this.playerIds.length; i++) {
                videojs.getPlayer(this.playerIds[i]).src({
                    type: 'audio/mp4',
                    src: mp4
                });
            }
        }

        videojs('player1').ready(function () {
            var player = this;
            player.hotkeys({
                enableVolumeScroll: false,
                seekStep: 15,
                rewindKey: function (event, player) {
                    return (event.which === 37 && !parent.keyboardPresses.includes(16))
                },
                forwardKey: function (event, player) {
                    return (event.which === 39 && !parent.keyboardPresses.includes(16))
                }
            });

            player.caption({
                data: 'this is a test'
            })
        });





        //this.addCustomRealTimeDisplay()
        //Demo purposes languages & qualities
        this.audioLang = 'or'
        this.audioLangs = ['or', 'fr', 'en']
        this.qualityLevels = ['high', 'low', 'med']
        this.quality = 'high'

        this.addLanguageControl();
        this.addQualityControl();

        // Init
        let parent = this
        this.playBtn = $('.vjs-play-control');
        this.volumeBtn = $('.vjs-mute-control');
        this.progressControlBtn = $('.vjs-progress-control');
        this.playbackRateBtn = $('.vjs-playback-rate');
        this.fullScreenBtn = $('.vjs-fullscreen-control');
        this.qualityBtn = $('.vjs-quality-control')
        this.languageBtn = $('.vjs-language-control')
        this.subsBtn = $('.vjs-subs-caps-button')


        //set focus on first button
        this.showFocus(this.playBtn);

        window.addEventListener('keydown', this.keysNavDownHandler.bind(this, parent), false);
        window.addEventListener('keyup', this.keysNavUpHandler.bind(this), false);


    }

    init(playerIds, channelIds, playlist = [], reference, playersInitialised, options = null) {
        this.ids = playerIds;
        this.playlist = playlist;
        this.reference = reference;
        this.ports = playlist.ports;
        this.server = playlist.server;
        this.clientIp = playlist.clientIp;
        this.posterIMG = '';
        this.playerIds = playerIds;
        this.status = null;
        this.enableSync = true
        this.mainPlayer = this.ids[0]
        this.retries = 0;
        this.retries_limit = 5;
        this.player = null;
        this.isPlaying = false;
        this.subtitles = []
        this.dvr = false

        this.smil = null;
        if (this.playlist.playlists) {
            this.smil = this.playlist.playlists[0].playlist[0].source.smil
        }


        console.log('-------- CREATING instances --------');

        this.destroy();

        this.createInstance(this.ids);

        this.ids.forEach(id => {
            this.player = videojs(id, {
                controlBar: {
                    'pictureInPictureToggle': false
                },
                liveui: true,
                liveTracker: true,
            });
        });

        if (options) {
            this.planned = options.planned
            this.private = options.private
            this.recording = options.recording
            this.status = options.status

            if (this.status === 'invalid') {
                this.status = 'done'
            }

            this.public = options.public
            this.locked = options.locked
            this.secure = options.secure
            this.published = options.published
            this.multicast = options.multicast
            this.dvr = options.dvr
            this.preview = options.preview
            this.startUTC = options.startUTC
        }

        let parent = this

        if (this.public === "0" && this.status !== 'live') {
            this.status = 'internal'
        }

        if (this.status !== 'internal' && this.secure === "1" && this.status !== 'live') {
            this.status = 'secure'
        }

        //Each channel has its own source, here the proper Media URL will be created and attached to the proper videojs instance.
        console.log('-------- generating media url and attaching to player instances --------');

        for (var i = 0; i < this.playerIds.length; i++) {
            if (this.preview && this.status === 'vod-unavailable') this.status = 'vod'

            if ((this.status !== 'planned' && this.status !== 'done' && this.status !== 'invalid' && this.status !== 'vod-unavailable' && this.status !== 'live-unavailable' && this.status !== 'internal') || (this.status === 'internal' && this.playlist.valid)) {
                if (this.multicast && (typeof this.multicast === 'object' && this.multicast !== null)) {
                    parent.setStatusMessages('Please watch this session using <a  id="webcastlink" href="ecwebcast://' + this.reference + '">EC Webcast Player</a>', 'ecwebcast')

                } else {
                    if (this.playlist) {
                        videojs.getPlayer(this.playerIds[i]).src({
                            type: 'application/x-mpegURL',
                            src: this.mediaUrl(i, 0)
                        });

                    } else {
                        videojs.getPlayer(this.playerIds[i]).src({
                            type: 'application/x-mpegURL',
                            src: null
                        });
                        parent.setStatusMessages(this.translations.getTranslation(this.status.toLowerCase()), 'no-close')
                    }
                }
            } else {
                parent.setStatusMessages(this.translations.getTranslation(this.status.toLowerCase()), 'no-close')
                //videojs.getPlayer(this.ids[i]).reset()
            }

            //Add a poster, will be shown when page is loaded or audio only
            //TODO: Make url dynamic so that each customer can have its own poster
            videojs.getPlayer(this.ids[i]).poster(this.posterIMG);

            videojs(this.ids[i]).ready(function () {
                var player = this;

                player.hotkeys({
                    enableVolumeScroll: false,
                    seekStep: 15,
                    rewindKey: function (event, player) {
                        return (event.which === 37 && !parent.keyboardPresses.includes(16))
                    },
                    forwardKey: function (event, player) {
                        return (event.which === 39 && !parent.keyboardPresses.includes(16))
                    }
                });

                player.tech(true).on('retryplaylist', (event) => {
                    if (this.retries < this.retries_limit) {
                        this.retries++;
                    }

                    if (this.retries === this.retries_limit) {
                        // Give up and go offline.
                        this.retries++;
                        parent.setStatusMessages(parent.translations.getTranslation('done'), 'no-close')
                        parent.setCustomSource(null)
                        parent.stop()

                    }
                });

                player.on('error', function (e) {
                    e.stopImmediatePropagation();
                    var error = this.player().error();
                    console.log('error happened: ', error)
                });
            });


            /*
            videojs(this.playerIds[i]).on('waiting', (i) => {
                this.stop()
                setTimeout(() => {
                    this.play()
                }, 1000)
            })
            */

            //videojs('player1').ga();
        }

        if (this.ids.length > 1) {
            this.addCustomClickEvents()
        }


        this.player.on('waiting', () => {
            this.isPlaying = false
        })

        this.player.on('playing', () => {
            this.isPlaying = true
        })

        this.player.on('play', () => {
            if (this.status === 'live' && !this.dvr) {
                $('.vjs-seek-to-live-control').trigger('click')
            }
        })

        console.log('status: ', this.status)

        if (this.playlist && this.status !== 'live' && this.status !== 'planned') {
            this.addCustomRealTimeDisplay()
            this.createSeekBar(playlist)

            this.timeline = {
                currentItem: 0,
                playlist: playlist.playlists[0].playlist,
                finished: false,
                secondsPlayed: 0
            };

        }

        if (this.playlist && this.status !== 'planned') {
            this.addLanguageControl();
            this.addQualityControl();
            this.addSeekStepControl()
        }

        this.playBtn = $('.vjs-play-control');
        this.volumeBtn = $('.vjs-mute-control');
        this.progressControlBtn = $('.vjs-progress-control');
        this.playbackRateBtn = $('.vjs-playback-rate');
        this.fullScreenBtn = $('.vjs-fullscreen-control');
        this.qualityBtn = $('.vjs-quality-control');
        this.languageBtn = $('.vjs-language-control');
        this.subsBtn = $('.vjs-subs-caps-button')

        //set focus on first button
        this.showFocus(this.playBtn);

        window.addEventListener('keydown', this.keysNavDownHandler.bind(this, parent), false);
        window.addEventListener('keyup', this.keysNavUpHandler.bind(this), false);

        playersInitialised();
    }

    //so I can see what has focus
    showFocus(item) {
        $(".showFocus").each(function (index, el) {
            $(el).removeClass("showFocus")
        });
        $(item).addClass("showFocus")
    }

    navigateControlBar(direction) {
        let player = $('#' + this.playerIds[0])
        this.languageBtn.removeClass('hover');
        this.qualityBtn.removeClass('hover');

        player.removeClass('vjs-user-inactive').addClass('vjs-user-active');
        if (this.volumeBtn.hasClass('showFocus') && direction === 'left') {
            this.showFocus(this.playBtn);
        } else if (this.playBtn.hasClass('showFocus') && direction === 'left') {
            this.showFocus(this.fullScreenBtn);

        } else if (this.playBtn.hasClass('showFocus') && direction === 'right') {
            this.showFocus(this.volumeBtn);

        } else if (this.volumeBtn.hasClass('showFocus') && direction === 'right') {
            if (this.subsBtn.length > 0) {
                this.showFocus(this.subsBtn)
            } else if (this.languageBtn.length > 0) {
                this.showFocus(this.languageBtn);
                $('.vjs-lang[data-lang = ' + this.audioLang + ']').addClass('showFocus')
            } else if (this.qualityBtn.length > 0) {

                this.showFocus(this.qualityBtn);
                $('.vjs-qual[data-qual = ' + this.quality + ']').addClass('showFocus')
            } else {
                this.showFocus(this.fullScreenBtn);
            }

        } else if (this.languageBtn.length > 0 && this.languageBtn.hasClass('showFocus') && direction === 'left') {
            if (this.subsBtn.length > 0) {
                this.showFocus(this.subsBtn)
            } else {
                this.showFocus(this.volumeBtn)
            }

        } else if (this.languageBtn.length > 0 && this.languageBtn.hasClass('showFocus') && direction === 'right') {
            if (this.qualityBtn.length > 0) {
                this.showFocus(this.qualityBtn)
            } else {
                this.showFocus(this.fullScreenBtn)
            }
        } else if (this.qualityBtn.length > 0 && this.qualityBtn.hasClass('showFocus') && direction === 'left') {
            if (this.languageBtn.length > 0) {
                this.showFocus(this.languageBtn)
                $('.vjs-lang[data-lang = ' + this.audioLang + ']').addClass('showFocus')
            } else {
                this.showFocus(this.volumeBtn)
            }
        } else if (this.subsBtn.length > 0 && this.subsBtn.hasClass('showFocus') && direction === 'right') {
            if (this.languageBtn.length > 0) {
                this.showFocus(this.languageBtn)
                $('.vjs-lang[data-lang = ' + this.audioLang + ']').addClass('showFocus')
            } else if (this.qualityBtn.length > 0) {
                this.showFocus(this.qualityBtn)
            } else {
                this.showFocus(this.fullScreenBtn)
            }
        } else if (this.subsBtn.length > 0 && this.subsBtn.hasClass('showFocus') && direction === 'left') {
            this.showFocus(this.volumeBtn)
        } else if (this.qualityBtn.length > 0 && this.qualityBtn.hasClass('showFocus') && direction === 'right') {
            this.showFocus(this.fullScreenBtn);
        } else if (this.qualityBtn.length > 0 && this.qualityBtn.hasClass('showFocus') && direction === 'left') {
            if (this.languageBtn.length > 0) {

                this.showFocus(this.languageBtn);
                $('.vjs-lang[data-lang = ' + this.audioLang + ']').addClass('showFocus')
            } else if (this.subsBtn.length > 0) {
                this.showFocus(this.subsBtn)
            } else {
                this.showFocus(this.volumeBtn)
            }
        } else if (this.fullScreenBtn.hasClass('showFocus') && direction === 'right') {
            this.showFocus(this.playBtn);
        } else if (this.fullScreenBtn.hasClass('showFocus') && direction === 'left') {
            if (this.qualityBtn.length > 0) {
                this.showFocus(this.qualityBtn)
            } else if (this.languageBtn.length > 0) {
                this.showFocus(this.languageBtn)
                $('.vjs-lang[data-lang = ' + this.audioLang + ']').addClass('showFocus')
            } else if (this.subsBtn.length > 0) {
                this.showFocus(this.subsBtn)
            } else {
                this.showFocus(this.volumeBtn)
            }
        }
    }

    closeControlBar() {
        this.player.removeClass('vjs-user-active').addClass('vjs-user-inactive');
    }

    triggerControlBtns() {
        let player = $('#' + this.playerIds[0])

        if (player.hasClass('vjs-user-inactive')) {
            player.removeClass('vjs-user-inactive').addClass('vjs-user-active');
        } else if (this.playBtn.hasClass('showFocus')) {
            this.playBtn.trigger('click');
        } else if (this.volumeBtn.hasClass('showFocus')) {
            this.volumeBtn.trigger('click');
        } else if (this.fullScreenBtn.hasClass('showFocus')) {
            this.fullScreenBtn.trigger('click');
        } else if (this.languageBtn.hasClass('showFocus')) {
            this.languageBtn.addClass('hover');
        } else if (this.qualityBtn.hasClass('showFocus')) {
            this.qualityBtn.addClass('hover');
        } else if (this.subsBtn.hasClass('showFocus')) {
            this.subsBtn.trigger('click')
        }
    }

    /* ----------------------
// Navigation
------------------------- */
    enterButtonPressed() {
        this.triggerControlBtns();
    }

    rightButtonPressed() {
        this.navigateControlBar('right');
    }

    leftButtonPressed() {
        this.navigateControlBar('left');
    }

    backButtonPressed() {
        this.closeControlBar();
    }

    upButtonPressed() {
        if (this.languageBtn.hasClass('showFocus')) {
            this.navigateLanguages('up')
        } else if (this.qualityBtn.hasClass('showFocus')) {
            this.navigateQualities('up')
        }
    }

    downButtonPressed() {
        if (this.languageBtn.hasClass('showFocus')) {
            this.navigateLanguages('down')
        } else if (this.qualityBtn.hasClass('showFocus')) {
            this.navigateQualities('down')
        }
    }

    navigateLanguages(direction) {
        if (direction === 'up') {
            let $prev = $('.vjs-lang.showFocus').prev()
            $('.vjs-lang').removeClass('showFocus')
            if ($prev.length > 0) {
                $prev.addClass('showFocus')
            } else {
                $('.vjs-lang').last().addClass('showFocus')
            }
        } else if (direction === 'down') {
            let $next = $('.vjs-lang.showFocus').next()
            $('.vjs-lang').removeClass('showFocus')
            if ($next.length > 0) {
                $next.addClass('showFocus')
            } else {
                $('.vjs-lang').first().addClass('showFocus')
            }
        }
        this.lang = $('.vjs-lang.showFocus').data('lang')
        $('.vjs-lang.showFocus').trigger('click')

    }

    navigateQualities(direction) {
        if (direction === 'up') {
            let $prev = $('.vjs-qual.showFocus').prev()
            $('.vjs-qual').removeClass('showFocus')
            if ($prev.length > 0) {
                $prev.addClass('showFocus')
            } else {
                $('.vjs-qual').last().addClass('showFocus')
            }
        } else if (direction === 'down') {
            let $next = $('.vjs-qual.showFocus').next()
            $('.vjs-qual').removeClass('showFocus')
            if ($next.length > 0) {
                $next.addClass('showFocus')
            } else {
                $('.vjs-qual').first().addClass('showFocus')
            }
        }
        this.quality = $('.vjs-qual.showFocus').data('qual')
        $('.vjs-qual.showFocus').trigger('click')
    }

    //List of keys and function assigment
    keysNavDownHandler(event, parent) {
        var key = parent.which;

        if (!this.keyboardPresses.includes(key)) {
            this.keyboardPresses.push(key)
        }

        if (this.keyboardPresses.includes(16) && this.keyboardPresses.includes(key)) {

            switch (key) {
                case 83: //X (ps4), A (xbox), S (keyboard)
                    event.enterButtonPressed();
                    break;
                case 32: //X (ps4), A (xbox), S (keyboard)
                    event.enterButtonPressed();
                    break;
                case 13: //X (ps4), A (xbox), S (keyboard)
                    event.enterButtonPressed();
                    break;
                case 39: //ARROW_RIGHT
                    event.rightButtonPressed();
                    break;
                case 37: //ARROW_LEFT
                    event.leftButtonPressed();
                    break;
                case 68: //circle (ps4), B (xbox), D (keyboard)
                    event.backButtonPressed();
                    break;
                case 38:
                    event.upButtonPressed();
                    break;
                case 40:
                    event.downButtonPressed();
                    break;
            }
        }
    }

    keysNavUpHandler(event) {
        var key = event.which;

        if (this.keyboardPresses.includes(key)) {
            const index = this.keyboardPresses.indexOf(key);
            this.keyboardPresses.splice(index, 1)
        }
    }


    changePipPosition(position) {
        var $smallPlayer = $('.vjs-second-player')
        var positions = ['top-left', 'top-right', 'bottom-left', 'bottom-right']

        positions = positions.filter(pos => {
            return position !== pos
        })

        positions.forEach(pos => {
            $smallPlayer.removeClass(pos)
        })

        switch (position) {
            case 'top-left':
                $smallPlayer.addClass('top-left')
                break;
            case 'top-right':
                $smallPlayer.addClass('top-right')
                break;
            case 'bottom-left':
                $smallPlayer.addClass('bottom-left')
                break;
            case 'bottom-right':
                $smallPlayer.addClass('bottom-right')
                break;
        }
    }

    swapPlayers() {
        if (this.ids.length > 1) {
            $('#' + this.ids[1]).show()
            this.mainPlayer = this.mainPlayer == this.ids[0] ? this.ids[1] : this.ids[0]

            let $parent = $('#' + this.ids[0])
            let $child = $('#' + this.ids[1])

            let $parentPlayer = $parent.find('video')
            let $childPlayer = $child.find('video')

            $child.find('video').remove()
            $child.prepend($parentPlayer)

            $parent.find('video').remove()
            $parent.prepend($childPlayer)
        }
    }

    hidePresentation() {
        if (this.mainPlayer === this.ids[1]) {
            this.swapPlayers()
        }

        $('#' + this.ids[1]).hide()
    }

    hideVideo() {
        if (this.mainPlayer === this.ids[0]) {
            this.swapPlayers()
        }

        $('#' + this.ids[1]).hide()
    }

    createSeekBar(playlist) {
        moment.tz(Intl.DateTimeFormat().resolvedOptions().timeZone);

        let $seekbar = '<div id="seekbar"><div id="progress"><div id="seek"></div><div id="seekpoint"></div></div>'
        for (let i = 0; i < playlist.playlists.length; i++) {
            let item = playlist.playlists[i]
            item.playlist = item.playlist.sort((p1, p2) => (moment(p1.startTime) > moment(p2.startTime)) ? 1 : (moment(p1.startTime) < moment(p2.startTime)) ? -1 : 0);
            $seekbar += '<div id="playlist_' + (String(i + 1)) + '" class="playlist" data-item="' + i + '">'

            for (let ii = 0; ii < item.playlist.length; ii++) {
                let playlistItem = item.playlist[ii]
                let firstItem = item.playlist[0]
                let lastItem = item.playlist[item.playlist.length - 1]

                let totalDuration = moment(lastItem.stopUTC * 1000).diff(firstItem.startUTC * 1000);
                let itemDuration = moment(playlistItem.stopUTC * 1000).diff(playlistItem.startUTC * 1000);

                let itemDurationPercentage = ((itemDuration / totalDuration) * 100).toFixed(3)

                $seekbar += '<div id="playlist__item__' + (String(ii + 1)) + '" class="playlist__item" style="width:' + itemDurationPercentage + '%;" data-item="' + (String(ii)) + '"></div>'

                if (item.playlist[ii + 1]) {
                    let nextItem = item.playlist[ii + 1]

                    let pauseDuration = moment(nextItem.startUTC * 1000).diff(playlistItem.stopUTC * 1000);

                    let pauseDurationPercentage = ((pauseDuration / totalDuration) * 100).toFixed(3)

                    $seekbar += '<div class="playlist__pause" style="width:' + pauseDurationPercentage + '%;" data-item="pause"></div>'
                }
            }

            $seekbar += '</div>'
        }
        $seekbar += '</div>'

        $('.vjs-control-bar').append($seekbar)

        $('#seekbar').on('click', this.seekbarClickHandler.bind(this))

        videojs.getPlayer(this.playerIds[0]).on('play', (e) => {
            if (!this.progressInterval) {
                this.progressInterval = setInterval(() =>
                    this.updateProgressBar(), 500
                )
            }
        })

        videojs.getPlayer(this.playerIds[0]).on('pause', (e) => {
            clearInterval(this.progressInterval)
            this.progressInterval = null
        })

        videojs.getPlayer(this.playerIds[0]).on('ended', () => {
            this.playlistItemDone()
        });
    }

    addKeyboardEvents() {

    }

    updateProgressBar() {
        if (this.isPlaying) {
            let currentWidth = $('#progress').width()
            let totalWidth = $('#seekbar').width() + 1

            if (this.fullyPlayed) {
                this.fullyPlayed = false
                currentWidth = 0
                videojs.getPlayer(this.playerIds[0]).src({
                    type: 'application/x-mpegURL',
                    src: this.mediaUrl(0, this.timeline.currentItem)
                })
            }


            let startTime = this.playlist.playlists[0].playlist[0].startUTC

            let stopTime = this.playlist.playlists[0].playlist[this.playlist.playlists[0].playlist.length - 1].stopUTC

            let StartTimeCurrentPlaylist = this.playlist.playlists[0].playlist[this.timeline.currentItem].startUTC
            let secondsPlayedThisFile = this.player.currentTime()

            let secondsPlayed = moment(StartTimeCurrentPlaylist).diff(startTime) + secondsPlayedThisFile

            this.timeline.secondsPlayed = secondsPlayed

            let totalSeconds = moment(stopTime).diff(startTime)

            let newWidthPercentage = secondsPlayed / totalSeconds * 100

            $('#progress').width(newWidthPercentage + '%')
        }
    }

    playlistItemDone() {
        if (this.timeline.currentItem < this.playlist.playlists[0].playlist.length - 1) {
            let playlistItem = this.playlist.playlists[0].playlist[this.timeline.currentItem + 1]

            this.timeline = {
                currentItem: this.timeline.currentItem + 1,
                playlist: [playlistItem],
                finished: false
            };


            let mouseX = $('#playlist__item__' + (this.timeline.currentItem))[0].offsetLeft

            let seekBarWidth = $('#seekbar').width()
            $('#progress').width((mouseX / seekBarWidth) * 100 + '%')

            this.player.src({
                type: 'application/x-mpegURL',
                src: this.mediaUrl(0, this.timeline.currentItem)
            })


            this.player.ready(() => {
                let startTimeUTC = playlistItem.startUTC
                this.seek(startTimeUTC)
                this.play()
            });

        } else {
            this.fullyPlayed = true
            this.timeline = {
                currentItem: 0,
                playlist: this.playlist.playlists[0].playlist,
                finished: false
            };
        }
    }

    seekbarClickHandler(e) {
        let clickedPlaylistItemIndex = $(e.target).data('item')

        let clickedPlaylistChannelIndex = $(e.target).parent().data('item')
        let sameItem = true

        let mouseX = e.pageX - e.currentTarget.getBoundingClientRect().x

        if (clickedPlaylistItemIndex === 'pause') {
            mouseX = $(e.target).next()[0].offsetLeft
        }

        let seekBarWidth = $(e.currentTarget).width()
        $('#progress').width((mouseX / seekBarWidth) * 100 + '%')

        let clickedPos = 0
        let clickedpPlaylistWidth = $(e.target).width()

        if (e.offsetX === undefined) {
            clickedPos = e.pageX - progress.offset().left
        } else {
            clickedPos = e.offsetX;
        }

        if (clickedPlaylistItemIndex === 'pause') {
            clickedPlaylistItemIndex = $(e.target).next().data('item')

            if (!clickedPlaylistItemIndex) {
                clickedPlaylistItemIndex = this.playlist.playlists[0].playlist.length - 1
            }

            clickedPos = 0
        }

        let playlistItem = this.playlist.playlists[clickedPlaylistChannelIndex].playlist[clickedPlaylistItemIndex]

        if (clickedPlaylistItemIndex !== this.timeline.currentItem) {
            sameItem = false
            this.timeline = {
                currentItem: clickedPlaylistItemIndex,
                playlist: [playlistItem],
                finished: false
            };
        }

        let clickedPosPercentage = (clickedPos / clickedpPlaylistWidth) * 100
        let playlistItemDuration = moment(playlistItem.stopUTC * 1000).diff(playlistItem.startUTC * 1000)
        let player = videojs.getPlayer(this.playerIds[0])

        if (!sameItem) {
            player.src({
                type: 'application/x-mpegURL',
                src: this.mediaUrl(0, clickedPlaylistItemIndex)
            })

            if (this.subtitles.length > 0) {
                this.syncSubtitles()
            }

        }

        let seekLocationToSeconds = Math.round((playlistItemDuration * (clickedPosPercentage / 100)) / 1000)
        let startTimeUTC = playlistItem.startUTC
        let seekLocation = startTimeUTC + seekLocationToSeconds

        if (!sameItem) {
            this.player.ready(() => {
                this.seek(seekLocation)
                player.play()
            })

        } else {
            this.seek(seekLocation)

        }
    }

    setCustomSource(source) {
        for (var i = 0; i < this.playerIds.length; i++) {
            videojs.getPlayer(this.playerIds[i]).src(source);
        }
    }

    addLanguageControl() {
        let $fullScreenControl = $('#player1').find('.vjs-fullscreen-control');
        let tempLangPicker = ''


        if (this.audioLangs.length === 1) {
            //do nothing for SEG builds, EC need visuals
        } else {
            tempLangPicker = '<div class="vjs-menu-button vjs-menu-button-popup vjs-control vjs-button dropup vjs-language-control">' +
                '<button class="dropbtn dropbtn-lang">' + this.languages.getLanguage(this.audioLang) + '</button>' +
                '<div class="dropup-content">'


            if (typeof this.audioLangs === 'object' &&
                !Array.isArray(this.audioLangs) &&
                this.audioLangs !== null) {
                this.audioLangs = Object.values(this.audioLangs)
            }

            for (let lang of this.audioLangs) {
                tempLangPicker += '<span class="vjs-lang" data-lang="' + lang + '">' + this.languages.getLanguage(lang) + '</span>'
            }

            tempLangPicker += '</div></div>';
        }

        let $languagePicker = $(tempLangPicker)

        $languagePicker.insertBefore($fullScreenControl);

        $('.vjs-lang').on('click', this.changeLanguageClickHandler.bind(this))
    }

    addQualityControl() {
        let $fullScreenControl = $('#player1').find('.vjs-fullscreen-control')

        let tempQualityPicker = ''

        if (this.qualityLevels.length === 1) {
            //Do nothing for seg builds, EC needs visuals
        } else {
            tempQualityPicker = '<div class="vjs-menu-button vjs-menu-button-popup vjs-control vjs-button dropup vjs-quality-control">' +
                '<button class="dropbtn dropbtn-qual">' + this.quality + '</button>' +
                '<div class="dropup-content">';

            for (let quality of this.qualityLevels) {
                tempQualityPicker += '<span class="vjs-qual" data-qual="' + quality + '">' + quality + '</span>'
            }

            tempQualityPicker += '</div></div>';
        }

        let $qualityPicker = $(tempQualityPicker)

        $qualityPicker.insertBefore($fullScreenControl);

        $('.vjs-qual').on('click', this.changeQualityClickHandler.bind(this))
    }

    changeLanguageClickHandler(e) {
        this.changeLanguage($(e.currentTarget).data('lang'))
    }

    changeLanguage(lang) {
        this.audioLang = lang
        this.reloadPlayer()
        $('.dropbtn-lang').html(this.languages.getLanguage(lang));
    }

    changeQualityClickHandler(e) {
        this.changeQuality($(e.currentTarget).html())
    }

    changeQuality(qual) {
        this.quality = qual
        this.reloadPlayer()
        $('.dropbtn-qual').html(this.quality);
    }

    addSeekStepControl() {
        let $fullScreenControl = $('#player1').find('.realTime')

        let $seekStepControl = '<button class="stepBack vjs-button"><span class="number">15</span><span class="vjs-icon-replay"></span></button>'
        $seekStepControl += '<button class="stepForward vjs-button"><span class="number">15</span><span class="vjs-icon-replay"></span></button>'


        $($seekStepControl).insertBefore($fullScreenControl);

        $('.stepBack').on('click', (e) => {

            e.preventDefault()

            let currentPos = this.getCurrentPosition().dateObject
            var t = new Date();
            currentPos.setSeconds(currentPos.getSeconds() - 15);
            this.seek(currentPos.getTime() / 1000)

            return false
        })


        $('.stepForward').on('click', (e) => {

            e.preventDefault()

            let currentPos = this.getCurrentPosition().dateObject
            var t = new Date();
            currentPos.setSeconds(currentPos.getSeconds() + 15);
            this.seek(currentPos.getTime() / 1000)

            return false
        })
    }

    reloadPlayer() {
        if (this.status === 'live') {

            let tempPosition = this.getCurrentPosition().timePassed

            this.player.src({
                type: 'application/x-mpegURL',
                src: this.mediaUrl(0, 0)
            });

            this.player.ready(() => {
                if (this.dvr) {

                    this.seek(tempPosition)
                }

            })
        } else {
            let tempPosition = this.getCurrentPosition().utc / 1000
            let playListItem = this.getPlaylistItemFromPosition(Math.round(tempPosition))

            this.player.src({
                type: 'application/x-mpegURL',
                src: this.mediaUrl(0, playListItem.currentItem)
            });


            this.player.ready(() => {
                this.seek(tempPosition)
            })
        }





        /*         for (var i = 0; i < this.playerIds.length; i++) {
                    videojs.getPlayer(this.playerIds[i]).pause()

                    videojs.getPlayer(this.playerIds[i]).src({
                        type: 'application/x-mpegURL',
                        src: this.mediaUrl(i, 0)
                    });
                } */
    }

    addCustomRealTimeDisplay() {
        var Button = videojs.getComponent('Button');

        var MyButton = videojs.extend(Button, {
            constructor: function () {
                Button.apply(this, arguments);
                this.addClass('realTime')
                this.addClass('dissabled')
                /* initialize your button */
            }
        });

        videojs.registerComponent('MyButton', MyButton);

        var player = videojs(this.playerIds[0]);
        player.getChild('controlBar').addChild('myButton', {}, 2);

        let item = this.playlist.playlists[0]
        let firstItem = item.playlist[0]
        let lastItem = item.playlist[item.playlist.length - 1]
        let totalDuration = moment(lastItem.stopUTC * 1000).diff(firstItem.startUTC * 1000);

        var startTime = moment(firstItem.startTime)

        //$('.realTime').text(startTime.format('HH:mm:ss') + "   /   " + moment(lastItem.stopTime).format('HH:mm:ss'))
        $('.realTime').text('On Demand: ' + startTime.format('HH:mm:ss'))
        var newTime = Object.assign({}, startTime)

        player.on('timeupdate', (e) => {

            newTime = moment(startTime).add(this.timeline.secondsPlayed, 'seconds')

            //$('.realTime').text(newTime.format('HH:mm:ss') + "   /   " + moment(lastItem.stopTime).format('HH:mm:ss'))
            $('.realTime').text('On Demand: ' + newTime.format('HH:mm:ss'))
        })
    }

    setPoster(img) {
        videojs.getPlayer(this.ids[0]).poster(img)
    }

    destroy() {
        let allPlayers = videojs.getAllPlayers();

        if (Object.keys(allPlayers).length !== 0) {
            allPlayers.forEach(player => {
                player.dispose();
            });
        }

        $('#playerwrap').empty();
    }

    isActive() {
        return !videojs.getPlayer(this.ids[0]).paused();
    }

    addCustomClickEvents() {
        var player1 = videojs.getPlayer(this.ids[0])
        var player2 = videojs.getPlayer(this.ids[1])
        player2.muted(true);

        player1.on('play', function () {
            player2.play()
        });

        player1.on('pause', function () {
            player2.pause()
        })

        player1.on("seeking", function (e) {
            player2.currentTime(player1.currentTime());
        });
    }

    seek(utc, autoplay = true) {
        let playlistItem = this.getPlaylistItemFromPosition(utc)
        let player = videojs.getPlayer(this.playerIds[0])

        let date = new Date(utc);

        if (!(Math.abs(Date.now() - date) < Math.abs(Date.now() - date * 1000))) {
            utc *= 1000
        }

        if (playlistItem && (playlistItem.currentItem !== this.timeline.currentItem)) {

            this.timeline = playlistItem

            player.src({
                type: 'application/x-mpegURL',
                src: this.mediaUrl(0, this.timeline.currentItem)
            })

        }


        let startTime = this.timeline.playlist[0].startUTC

        if (this.status === 'live' && this.dvr) {
            startTime = this.startUTC
        }


        date = new Date(startTime);

        if (!(Math.abs(Date.now() - date) < Math.abs(Date.now() - date * 1000))) {
            startTime *= 1000
        }

        let timeDiff = moment.duration(moment(utc).diff(moment(startTime))).valueOf()

        if (this.status !== 'live') {
            let startutc = this.playlist.playlists[0].playlist[0].startUTC * 1000

            let endutc = this.playlist.playlists[0].playlist[this.playlist.playlists[0].playlist.length - 1].stopUTC * 1000


            let totalDuration = moment(endutc).diff(moment(startutc), 'seconds')

            let seekDuration = moment(utc).diff(moment(startutc), 'seconds')

            let seekPercentage = seekDuration / totalDuration * 100

            $('#progress').width(seekPercentage + '%')
        }


        player.ready(() => {
            this.player.currentTime(timeDiff / 1000)
            if (this.subtitles.length > 0) this.syncSubtitles()
            if (autoplay) this.play()
        });
    }

    getPlaylistItemFromPosition(utc) {
        let item = null

        for (let i = 0; i < this.playlist.playlists[0].playlist.length; i++) {
            let playlistItem = this.playlist.playlists[0].playlist[i]

            if (this.status === 'live' || (utc >= playlistItem.startUTC && utc < playlistItem.stopUTC)) {
                item = {
                    currentItem: i,
                    playlist: [playlistItem],
                    finished: false
                }
            }
        }

        return item
    }

    createInstance(ids) {
        let HTMLcontainer = '<div id="smvplayer">';
        // HTMLcontainer += '<div id="live-trans"><p></p></div>'
        ids.forEach(function (id) {
            if (ids.length > 1 && id === ids[1]) {
                HTMLcontainer += '<video id="' + id + '" class="video-js vjs-theme-sea vjs-big-play-centered vjs-16-9 vjs-second-player" preload="none" playsinline></video>';
            } else {
                HTMLcontainer += '<video id="' + id + '" controls="false" class="video-js vjs-theme-sea vjs-big-play-centered vjs-16-9" preload="none" playsinline></video>';
            }
        });

        HTMLcontainer += '</div>';

        $('#playerwrap').append(HTMLcontainer)
    }

    getCurrentPosition() {
        moment.tz(Intl.DateTimeFormat().resolvedOptions().timeZone);

        let player = videojs.getPlayer(this.ids[0]);

        if (player && this.playlist && this.status !== 'live') {
            let startutc = this.playlist.playlists[0].playlist[0].startUTC * 1000
            let endutc = this.playlist.playlists[0].playlist[this.playlist.playlists[0].playlist.length - 1].stopUTC * 1000

            let progressPercentage = $('#progress').width() / ($('#seekbar').width() + 1)

            if (progressPercentage > 1) {
                this.fullyPlayed = true
                progressPercentage = 1
            }

            let timePassed = this.timeline.secondsPlayed

            let playlistTime = this.playlist.playlists[0].playlist[0].startUTC * 1000

            let calculatedTime = moment(playlistTime).add(timePassed, 'seconds')

            return {
                'timePassed': timePassed,
                'utc': calculatedTime.valueOf(),
                'realTime': moment(calculatedTime).format('HH:mm:ss'),
                'dateObject': moment(calculatedTime).toDate()
            }
        } else {
            let seekedSeconds = player.currentTime()

            return {
                'timePassed': seekedSeconds,
		'duration': player.liveTracker.liveCurrentTime()
            };
        }
    }

    setStatusMessages(message, customClass = '') {
        $('#smvplayer').addClass('modal-open')

        var contentEl = document.createElement('div');
        contentEl.innerHTML = message;

        let modal = videojs(this.ids[0]).createModal(contentEl)

        modal.addClass('modal-style')
        modal.addClass(customClass)
    }

    getStatus() {
        return this.status
    }

    play() {
        Object.values(videojs.getPlayers()).forEach(
            player => {
                this.playPromises.push(player.play());
                this.retries = 0;
            }
        );
    }

    stop() {
        Object.values(videojs.getPlayers()).forEach(
            player => {
                Promise.all(this.playPromises).then(() => {
                    player.pause()
                    this.playPromises = []
                })
            }
        );
    }

    getCurrentPlaylistItem() {
        return this.playlist;
    }

    // Builds a mediaURL from the supplied MediaSetup / Playlist
    mediaUrl(channelIndex, playlistIndex) {
        var sMediaUrl = "";

        //id = this.ids[0];

        var source;


        if (this.getCurrentPlaylistItem()) {
            source = this.getCurrentPlaylistItem();
        } else {
            sMediaUrl = "na";
            return sMediaUrl;
        }

        var qualities = Object.keys(source.playlists[channelIndex].playlist[playlistIndex].source.qualities);

        this.qualityLevels = qualities


        if (this.smil && (this.quality == "")) {
            this.qualityLevels.unshift("Auto")
            this.quality = "Auto"
        }


        this.audioLangs = source.playlists[channelIndex].playlist[playlistIndex].source.languages

        //var source = timeline.playlist[timeline.currentItem].source;

        if (source.playlists[channelIndex].playlist[playlistIndex].source.streamType === "none") {
            sMediaUrl = "na";
            return sMediaUrl;
        }

        var protocol = "";

        if (source.forceProtocol) {
            if (source.forceProtocol !== false && !source.multicast) {
                console.log("[mediaUrl] ForceProtocol set to: " + source.forceProtocol);
                protocol = source.forceProtocol;
            } else if (source.multicast !== false && !this.unicastFallback) {
                protocol = 'rtsp';
            } else if (source.forceProtocol !== false) {
                console.log("[mediaUrl] ForceProtocol set to: " + source.forceProtocol);
                protocol = source.forceProtocol;
            }
        } else {
            if (window.location.protocol == "https:") {
                protocol = "https";
            } else {
                protocol = "http";
            }
        }
        sMediaUrl = protocol + '://';

        var serverPath = "";
        if (this.unicastFallback || !source.multicast) {
            if (!this.ports[protocol] && protocol == "https") {
                serverPath += source.server + ":" + '443' + '/' + source.application + "/";
            } else {
                serverPath += source.server + ":" + this.ports[protocol] + '/' + source.application + "/";
            }
        } else {
            // MULTICAST SERVER
            if (!this.ports[protocol] && protocol == "https") {
                serverPath += source.multicast.server + ":" + '443' + '/' + source.application + "/";
            } else {
                serverPath += source.multicast.server + ":" + this.ports[protocol] + '/' + source.application + "/";
            }
        }

        sMediaUrl += serverPath;

        var mediaFile = "";
        var mode = "";

        if (this.quality === 'Auto') {
            mode = "SMIL";
        } else {
            mode = "FALLBACK";
        }

        // Required since apaprntly the UI adds an enter character into the quality for no reasoning.
        if (this.quality) {
            this.quality = this.quality.trim();
        }

        if (!this.quality || this.quality === '') {
            if (source.playlists[channelIndex].playlist[playlistIndex].source.qualities.hasOwnProperty("high")) {
                this.quality = 'high';
            } else {
                for (var qualName in source.playlists[channelIndex].playlist[playlistIndex].source.qualities) {
                    this.quality = qualName;
                    break; // neem de laatste -> hoogste qualiteit want komt van low naar high binnen uit cms
                }
            }
        }

        //console.log('SELECTED QUALITY: '+quality);
        switch (mode) {
            case "SMIL":
                // Live
                if (source.playlists[channelIndex].playlist[playlistIndex].source.streamType === 'live') {

                    mediaFile = source.playlists[channelIndex].playlist[playlistIndex].source.smil[this.audioLang];
                }
                // Vod
                else if (source.playlists[channelIndex].playlist[playlistIndex].source.streamType === 'vod') {

                    mediaFile = source.playlists[channelIndex].playlist[playlistIndex].source.smil;
                }

                if (!mediaFile) {

                    for (var qualName in source.playlists[channelIndex].playlist[playlistIndex].source.qualities) {
                        this.quality = qualName;
                        //break; // neem de laatste -> hoogste qualiteit want komt van low naar high binnen uit cms
                    }

                    mode = 'FALLBACK';

                    // Live
                    if (source.playlists[channelIndex].playlist[playlistIndex].source.streamType === 'live') {

                        mediaFile = source.playlists[channelIndex].playlist[playlistIndex].source.qualities[this.quality][this.audioLang];
                    }
                    // Vod
                    else if (source.playlists[channelIndex].playlist[playlistIndex].source.streamType === 'vod') {

                        mediaFile = source.playlists[channelIndex].playlist[playlistIndex].source.qualities[this.quality];

                    } else {}
                }


                break;
            case "FALLBACK":
                // Double check if quality is not auto and smil stuffies
                if (this.quality == "Auto") {


                    if (source.playlists[channelIndex].playlist[playlistIndex].source.streamType === 'live') {

                        mediaFile = source.playlists[channelIndex].playlist[playlistIndex].source.smil[this.audioLang];
                    } else {

                        mediaFile = source.playlists[channelIndex].playlist[playlistIndex].source.smil;
                    }

                } else {
                    // Live
                    if (source.playlists[channelIndex].playlist[playlistIndex].source.streamType === 'live') {

                        mediaFile = source.playlists[channelIndex].playlist[playlistIndex].source.qualities[this.quality][this.audioLang];
                    }
                    // Vod
                    else if (source.playlists[channelIndex].playlist[playlistIndex].source.streamType === 'vod') {

                        mediaFile = source.playlists[channelIndex].playlist[playlistIndex].source.qualities[this.quality];

                    } else {}

                }
                break;
        }

        //console.log('mediaUrl() mode : '+mode);
        //console.log('mediaUrl() mediaFile : '+mediaFile);

        if (mode === "SMIL") {

            //console.log('indexOf: ', source.application.indexOf('_definst_') );
            //console.log('source.application: ', source.application );

            // If the application has "_definst_" don't add it before the smil. Otherwise do add it behind it.
            if (source.application.indexOf('_definst_') > -1) {
                sMediaUrl += 'smil:';
            } else {
                sMediaUrl += '_definst_/smil:';
            }
        }

        sMediaUrl += mediaFile;


        var appendix = "";

        if (protocol === 'http' || protocol === 'https') {
            appendix = "/playlist.m3u8";
        }

        // Live
        if (source.playlists[channelIndex].playlist[playlistIndex].source.streamType === "live") {
            if (source.playlists[channelIndex].playlist[playlistIndex].source.multicast) {
                appendix += "&multicastplay";
            }
        }
        // Vod
        else {
            appendix += "?tracks=" + this.audioLang;
        }


        // params voor start en stoptijd in de wowza
        if (source.start) {
            appendix += "&wowzaplaystart=" + parseInt(source.start);
        }
        if (source.stop) {
            appendix += "&wowzaplayduration=" + parseInt(source.stop);
        }


        // Add the appendix to the mediaUrl at the end.
        sMediaUrl += appendix;

        // Debug loggings
        console.log('[mediaUrl] Debug | mediaFile: ' + mediaFile + ' | mode: ' + mode + ' | quality: ' + this.quality + ' | language: ' + this.audioLang + ' | streamType: ' + source.streamType);
        console.log('[mediaUrl] Debug, mediaUrl: ' + sMediaUrl);

        if (this.sInitMediaUrl === false) {
            console.log('[mediaUrl] Debug | Setting the sInitMediaUrl to: ' + sMediaUrl);
            this.sInitMediaUrl = sMediaUrl;
        }

        // dvr parameter
        if (source.playlists[channelIndex].playlist[playlistIndex].source.streamType === "live" && this.dvr === true) {
            sMediaUrl += "?dvr";
            $('.vjs-progress-control').addClass('dvr-progress')
        }

        return sMediaUrl;
    };

    // Create a GUID for statistics on wowza \o/
    S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };

    addSubtitles(subs) {
        console.log('adding subtitles: ', subs)
        this.subtitles = subs
        this.syncSubtitles()
    }

    syncSubtitles() {
        //If there are subtitles and there is one for current item, generate the subtitle
        if (this.subtitles && this.subtitles[this.timeline.currentItem]) {
            const textTracks = this.player.textTracks();

            // Loop through all the tracks and remove subtitle tracks
            for (let i = textTracks.length - 1; i >= 0; i--) {
                const track = textTracks[i];
                if (track.kind === 'subtitles') {
                    textTracks.removeTrack(track);
                }
            }



            let sub = this.subtitles[this.timeline.currentItem]

            let webvtt = (() => {
                let webvtt = "WEBVTT\n\n";
                sub.data.forEach((subtitle, index) => {
                    const startTime = new Date(subtitle.start).toISOString().substr(11, 12);
                    const endTime = new Date(subtitle.end).toISOString().substr(11, 12);
                    webvtt += `${index+1}\n${startTime} --> ${endTime}\n${subtitle.text}\n\n`;
                });
                return webvtt;
            })()

            const blob = new Blob([webvtt], {
                type: "text/vtt"
            });


            let blobURL = URL.createObjectURL(blob)

            console.log(blobURL);

            this.player.addRemoteTextTrack({
                kind: 'subtitle',
                src: blobURL,
                srclang: sub.language,
                label: sub.label
            }, true)

            // Loop through all the tracks and remove metadata tracks

            for (let i = textTracks.length - 1; i >= 0; i--) {
                const track = textTracks[i];
                if (track.kind === 'metadata') {
                    textTracks.removeTrack(track);
                }
            }


            if (textTracks && textTracks.length > 0) {

                textTracks[0].mode = 'showing';
            }
        } else {
            //If there are subtitles but no subtitle for current playing item, remove all subtitles to hide them.
            if (this.subtitles && !this.subtitles[this.timeline.currentItem]) {
                const textTracks = this.player.textTracks();

                // Loop through all the tracks and remove subtitle tracks
                for (let i = textTracks.length - 1; i >= 0; i--) {
                    const track = textTracks[i];
                    if (track.kind === 'subtitles') {
                        textTracks.removeTrack(track);
                    }
                }
            }
        }
    }


    addSubs(subs) {
        let sub = subs[0]
        let blob = new Blob([sub.data], {
            type: "text/vtt"
        });

        let blobURL = URL.createObjectURL(blob)

        this.player.addRemoteTextTrack({
            src: blobURL,
            srclang: sub.language,
            label: sub.label
        }, true)

        $('.vjs-subtitles-menu-item').find('span:contains("nl-BE")').trigger('click')
    }

    srt2webvtt(data) {
        // remove dos newlines
        var srt = data.replace(/\r+/g, '');
        // trim white space start and end
        srt = srt.replace(/^\s+|\s+$/g, '');

        // get cues
        var cuelist = srt.split('\n\n');
        var result = "";

        if (cuelist.length > 0) {
            result += "WEBVTT\n\n";
            for (var i = 0; i < cuelist.length; i = i + 1) {
                result += this.convertSrtCue(cuelist[i]);
            }
        }

        return result;
    }

    convertSrtCue(caption) {
        // remove all html tags for security reasons
        //srt = srt.replace(/<[a-zA-Z\/][^>]*>/g, '');

        var cue = "";
        var s = caption.split(/\n/);

        // concatenate muilt-line string separated in array into one
        while (s.length > 3) {
            for (var i = 3; i < s.length; i++) {
                s[2] += "\n" + s[i]
            }
            s.splice(3, s.length - 3);
        }

        var line = 0;

        // detect identifier
        if (!s[0].match(/\d+:\d+:\d+/) && s[1].match(/\d+:\d+:\d+/)) {
            cue += s[0].match(/\w+/) + "\n";
            line += 1;
        }

        // get time strings
        if (s[line].match(/\d+:\d+:\d+/)) {
            // convert time string
            var m = s[1].match(/(\d+):(\d+):(\d+)(?:,(\d+))?\s*--?>\s*(\d+):(\d+):(\d+)(?:,(\d+))?/);
            if (m) {
                cue += m[1] + ":" + m[2] + ":" + m[3] + "." + m[4] + " --> " +
                    m[5] + ":" + m[6] + ":" + m[7] + "." + m[8] + "\n";
                line += 1;
            } else {
                // Unrecognized timestring
                return "";
            }
        } else {
            // file format error or comment lines
            return "";
        }

        // get cue text
        if (s[line]) {
            cue += s[line] + "\n\n";
        }

        return cue;
    }

    addLiveCaption(caption) {

        let $liveTrans = $('#live-trans')


        if (!$liveTrans.length) {
            $('.video-js').prepend('<div id="live-trans"><p></p></div>')
        }

        let player = videojs.getPlayer(this.playerIds[0])


        if (player.liveTracker.behindLiveEdge()) {
//            $liveTrans.hide()
        } else {
            $liveTrans.show()
        }
        $liveTrans = $('#live-trans')

        $liveTrans.removeClass('hide')
        if (!this.isPlaying) {
            $liveTrans.addClass('hide')
        }
        if (this.liveTranscriptions.length === 1) {
            this.liveTranscriptions.shift();
        }

        this.liveTranscriptions.push(caption)

        $liveTrans.find('p').text('')
        let textToShow = ''
        this.liveTranscriptions.forEach((value, index) => {

            let transClass = ''
            if (index === 0 && this.liveTranscriptions.length === 1) {
                textToShow += '\n <span class="new">' + value + '</span>'
            } else if (index === 1) {
                textToShow += '\n <span class="new">' + value + '</span>'
            } else {
                textToShow += '\n <span>' + value + '</span>'
            }

        })

        $liveTrans.find('p').html(textToShow)

    }
}
