export default class translations {
    constructor() {
        this.trans = {
            'done': 'This session is finished',
            'vod-unavailable': 'This session is not available',
            'planned': 'This session is planned',
            'live-unavailable': 'This session is done',
            'internal' :'This session is not publicly available',
            'secure':  'This session is not publicly available'
        } 
    }

    getTranslation(value) {
        console.log('getting translation: ', value)
        for (let [key, transValue] of Object.entries(this.trans)) {
            if(value === key) {
                return transValue
            }
        }
    }
}
