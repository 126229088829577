export default class languages {
    constructor() {
        this.langs = {
                'or': 'Original language (or)',									// ‘or’ Hijacked for Original Language (reference language/source)
                //'aa': 'Afaraf (aa)',                      // Custom Language within EC.
                'ab': '&#x410;&#x4A7;&#x441;&#x443;&#x430; (ab)',   // Abchazisch
                'ae': 'Avesta (ae)',    // Avestisch
                'af': 'Afrikaans (af)', // Afrikaans
                'ak': 'Akan (ak)',  // Akan
                'am': '&#x12A0;&#x121B;&#x122D;&#x129B; (am)',  // Amharic
                'an': 'Aragon&#xE9;s (an)', // Aragonese
                'ar': '&#x627;&#x644;&#x639;&#x631;&#x628;&#x64A;&#x629; (ar)', // Arabic
                'as': '&#x985;&#x9B8;&#x9AE;&#x9C0;&#x9AF;&#x9BC;&#x9BE; (as)', // Assamese
                'av': '&#x43C;&#x430;&#x433;&#x4C0;&#x430;&#x440;&#x443;&#x43B; &#x43C;&#x430;&#x446;&#x4C0; (av)',	// Avaric
                'ay': 'Aymar aru (ay)', // Aymara
                'az': 'Az&#x259;rbaycan dili (az)',
                'ba': '&#x431;&#x430;&#x448;&#x4A1;&#x43E;&#x440;&#x442; &#x442;&#x435;&#x43B;&#x435; (ba)',
                'be': '&#x431;&#x435;&#x43B;&#x430;&#x440;&#x443;&#x441;&#x43A;&#x430;&#x44F; &#x43C;&#x43E;&#x432;&#x430; (be)',
                'bg': '&#x431;&#x44A;&#x43B;&#x433;&#x430;&#x440;&#x441;&#x43A;&#x438; &#x435;&#x437;&#x438;&#x43A; (bg)',
                'bh': '&#x92D;&#x94B;&#x91C;&#x92A;&#x941;&#x930;&#x940; (bh)',
                'bi': 'Bislama (bi)',
                'bm': 'Bamanankan (bm)',
                'bn': '&#x9AC;&#x9BE;&#x982;&#x9B2;&#x9BE; (bn)',
                'bo': '&#xF56;&#xF7C;&#xF51;&#xF0B;&#xF61;&#xF72;&#xF42; (bo)',
                'br': 'Brezhoneg (br)',
                'bs': 'Bosanski jezik (bs)',
                'ca': 'Catal&#xE0; (ca)',
                'ce': '&#x43D;&#x43E;&#x445;&#x447;&#x438;&#x439;&#x43D; &#x43C;&#x43E;&#x442;&#x442; (ce)',
                'ch': 'Chamoru (ch)',
                'co': 'Corsu (co)',
                'cr': 'Romani (xr)', // 'CR label -> XR' for our kings and queens at the EC
                'cs': '&#x10D;e&#x161;tina (cs)',
                'cu': '&#x469;&#x437;&#x44B;&#x43A;&#x44A; &#x441;&#x43B;&#x43E;&#x432;&#x463;&#x43D;&#x44C;&#x441;&#x43A;&#x44A; (cu)',
                'cv': '&#x447;&#x4D1;&#x432;&#x430;&#x448; &#x447;&#x4D7;&#x43B;&#x445;&#x438; (cv)',
                'cy': 'Cymraeg (cy)',
                'da': 'Dansk (da)',
                'de': 'Deutsch (de)',
                'dv': '&#x78B;&#x7A8;&#x788;&#x7AC;&#x780;&#x7A8; (dv)',
                'dz': '&#xF62;&#xFAB;&#xF7C;&#xF44;&#xF0B;&#xF41; (dz)',
                'ee': 'E&#x28B;egbe (ee)',
                'el': '&#x3B5;&#x3BB;&#x3BB;&#x3B7;&#x3BD;&#x3B9;&#x3BA;&#x3AC; (el)',
                'en': 'English (en)',
                'eo': 'Esperanto (eo)',
                'es': 'Espa&#xF1;ol (es)',
                'et': 'Eesti keel (et)',
                'eu': 'Euskara (eu)',
                'fa': '&#x641;&#x627;&#x631;&#x633;&#x6CC; (fa)',
                'ff': 'Fulfulde (ff)',
                'fi': 'Suomi (fi)',
                'fo': 'F&#xF8;royskt (fo)',
                'fr': 'Fran&#xE7;ais (fr)',
                'fy': 'Frysk (fy)',
                'ga': 'Gaeilge (ga)',
                'gd': 'G&#xE0;idhlig (gd)',
                'gl': 'Galego (gl)',
                'gn': 'Ava&#xF1;e&#x27;&#x1EBD; (gn)',
                'gu': '&#xA97;&#xAC1;&#xA9C;&#xAB0;&#xABE;&#xAA4;&#xAC0; (gu)',
                'gv': 'Gaelg (gv)',
                'ha': '&#x647;&#x64E;&#x648;&#x64F;&#x633;&#x64E; (Hausa) (ha)',
                'he': '&#x5E2;&#x5D1;&#x5E8;&#x5D9;&#x5EA; (he)',
                'hi': '&#x939;&#x93F;&#x928;&#x94D;&#x926;&#x940; (hi)',
                'ho': 'Hiri Motu (ho)',
                'hr': 'Hrvatski jezik (hr)',
                'ht': 'Krey&#xF2;l ayisyen (ht)',
                'hu': 'Magyar (hu)',
                'hy': '&#x540;&#x561;&#x575;&#x565;&#x580;&#x565;&#x576; (hy)',
                'hz': 'Otjiherero (hz)',
                'ia': 'Interlingua (ia)',
                'id': 'Bahasa Indonesia (id)',
                'ie': 'Interlingue (ie)',
                'ig': 'As&#x1EE5;s&#x1EE5; Igbo (ig)',
                'ii': '&#xA188;&#xA320;&#xA4BF; Nuosuhxop (ii)',
                'ik': 'I&#xF1;upiatun (ik)',
                'io': 'Ido (io)',
                'is': '&#xCD;slenska (is)',
                'it': 'Italiano (it)',
                'iu': '&#x1403;&#x14C4;&#x1483;&#x144E;&#x1450;&#x1466; (iu)',
                'ja': '&#x65E5;&#x672C;&#x8A9E; (ja)',
                'jv': '&#xA9A7;&#xA9B1;&#xA997;&#xA9AE; (jv)',
                'ka': '&#x10E5;&#x10D0;&#x10E0;&#x10D7;&#x10E3;&#x10DA;&#x10D8; (ka)',
                'kg': 'Kikongo (kg)',
                'ki': 'G&#x129;k&#x169;y&#x169; (ki)',
                'kj': 'Kuanyama (kj)',
                'kk': '&#x49B;&#x430;&#x437;&#x430;&#x49B; &#x442;&#x456;&#x43B;&#x456; (kk)',
                'kl': 'Kalaallisut (kl)',
                'km': '&#x1781;&#x17D2;&#x1798;&#x17C2;&#x179A; (km)',
                'kn': '&#xC95;&#xCA8;&#xCCD;&#xCA8;&#xCA1; (kn)',
                'ko': '&#xD55C;&#xAD6D;&#xC5B4; (ko)',
                'kr': 'Kanuri (kr)',
                'ks': '&#x915;&#x936;&#x94D;&#x92E;&#x940;&#x930;&#x940; (ks)',
                'ku': 'Kurd&#xEE; (ku)',
                'kv': '&#x43A;&#x43E;&#x43C;&#x438; &#x43A;&#x44B;&#x432; (kv)',
                'kw': 'Kernewek (kw)',
                'ky': '&#x41A;&#x44B;&#x440;&#x433;&#x44B;&#x437;&#x447;&#x430; (ky)',
                'la': 'Latina (la)',
                'lb': 'L&#xEB;tzebuergesch (lb)',
                'lg': 'Luganda (lg)',
                'li': 'Limburgs (li)',
                'ln': 'Ling&#xE1;la (ln)',
                'lo': '&#xE9E;&#xEB2;&#xEAA;&#xEB2;&#xEA5;&#xEB2;&#xEA7; (lo)',
                'lt': 'Lietuvi&#x173; kalba',
                'lu': 'Tshiluba (lu)',
                'lv': 'Latvie&#x161;u valoda (lv)',
                'mg': 'Fiteny malagasy (mg)',
                'mh': 'Kajin M&#x327;aje&#x13C; (mh)',
                'mi': 'Te reo M&#x101;ori (mi)',
                'mk': '&#x43C;&#x430;&#x43A;&#x435;&#x434;&#x43E;&#x43D;&#x441;&#x43A;&#x438; &#x458;&#x430;&#x437;&#x438;&#x43A; (mk)',
                'ml': '&#xD2E;&#xD32;&#xD2F;&#xD3E;&#xD33;&#xD02; (ml)',
                'mn': '&#x41C;&#x43E;&#x43D;&#x433;&#x43E;&#x43B; &#x445;&#x44D;&#x43B; (mn)',
                'mr': '&#x92E;&#x930;&#x93E;&#x920;&#x940; (mr)',
                'ms': 'Bahasa Melayu (ms)',
                'mt': 'Malti (mt)',
                'my': '&#x1017;&#x1019;&#x102C;&#x1005;&#x102C; (my)',
                'na': 'Dorerin Naoero (na)',
                'nb': 'Norsk bokmål (nb)',
                'nd': 'Ndebele (nd)',
                'ne': '&#x928;&#x947;&#x92A;&#x93E;&#x932;&#x940; (ne)',
                'ng': 'Owambo (ng)',
                'nl': 'Nederlands (nl)',
                'nn': 'Norsk nynorsk (nn)',
                'no': 'Norsk (no)',
                'nr': 'Ndebele (nr)',
                'nv': 'Din&#xE9; bizaad (nv)',
                'ny': 'ChiChe&#x175;a (ny)',
                'oc': 'Occitan (oc)',
                'oj': '&#x140A;&#x14C2;&#x1511;&#x14C8;&#x142F;&#x14A7;&#x140E;&#x14D0; (oj)',
                'om': 'Afaan Oromoo (om)',
                    //'or': '&#xB13;&#xB21;&#xB3C;&#xB3F;&#xB06; (or)',		// Odia (Oriya) - Hijacked
                'os': '&#x438;&#x440;&#x43E;&#x43D; &#xE6;&#x432;&#x437;&#x430;&#x433; (os)',
                'pa': '&#xA2A;&#xA70;&#xA1C;&#xA3E;&#xA2C;&#xA40; (pa)',
                'pi': '&#x92A;&#x93E;&#x934;&#x93F; (pi)',
                'pl': 'J&#x119;zyk polski (pl)',
                'ps': '&#x67E;&#x69A;&#x62A;&#x648; (ps)',
                'pt': 'Portugu&#xEA;s (pt)',
                'qu': 'Runa Simi (qu)',
                'rm': 'Rumantsch (rm)',
                'rn': 'Ikirundi (rn)',
                'ro': 'Rom&#xE2;n&#x103; (ro)',
                'ru': '&#x420;&#x443;&#x441;&#x441;&#x43A;&#x438;&#x439; (ru)',
                'rw': 'Ikinyarwanda (rw)',
                'sa': '&#x938;&#x902;&#x938;&#x94D;&#x915;&#x943;&#x924;&#x92E;&#x94D; (sa)',
                'sc': 'Sardu (sc)',
                'sd': '&#x938;&#x93F;&#x928;&#x94D;&#x927;&#x940; (sd)',
                'se': 'Davvis&#xE1;megiella (se)',
                'sg': 'Y&#xE2;ng&#xE2; t&#xEE; s&#xE4;ng&#xF6; (sg)',
                'si': '&#xDC3;&#xDD2;&#xD82;&#xDC4;&#xDBD; (si)',
                'sk': 'Sloven&#x10D;ina (sk)',
                'sl': 'Slovenski jezik (sl)',
                'sm': 'Gagana fa&#x27;a Samoa (sm)',
                'sn': 'chiShona (sn)',
                'so': 'Soomaaliga (so)',
                'sq': 'Shqip (sq)',
                'sr': '&#x441;&#x440;&#x43F;&#x441;&#x43A;&#x438; &#x458;&#x435;&#x437;&#x438;&#x43A; (sr)',
                'ss': 'SiSwati (ss)',
                'st': 'Sesotho (st)',
                'su': 'Basa Sunda (su)',
                'sv': 'Svenska (sv)',
                'sw': 'Kiswahili (sw)',
                'ta': '&#xBA4;&#xBAE;&#xBBF;&#xBB4;&#xBCD; (ta)',
                'te': '&#xC24;&#xC46;&#xC32;&#xC41;&#xC17;&#xC41; (te)',
                'tg': '&#x442;&#x43E;&#x4B7;&#x438;&#x43A;&#x4E3; (tg)',
                'th': '&#xE44;&#xE17;&#xE22; (th)',
                'ti': '&#x1275;&#x130D;&#x122D;&#x129B; (ti)',
                'tk': 'T&#xFC;rkmen (tk)',
                'tl': 'Wikang Tagalog (tl)',
                'tn': 'Setswana (tn)',
                'to': 'Faka Tonga (to)',
                'tr': 'T&#xFC;rk&#xE7;e (tr)',
                'ts': 'Xitsonga (ts)',
                'tt': '&#x442;&#x430;&#x442;&#x430;&#x440; &#x442;&#x435;&#x43B;&#x435; (tt)',
                'tw': 'Twi (tw)',
                'ty': 'Reo Tahiti (ty)',
                'ug': '&#x626;&#x6C7;&#x64A;&#x63A;&#x6C7;&#x631;&#x686;&#x6D5;&#x200E; (ug)',
                'uk': '&#x423;&#x43A;&#x440;&#x430;&#x457;&#x43D;&#x441;&#x44C;&#x43A;&#x430; (uk)',
                'ur': '&#x627;&#x631;&#x62F;&#x648; (ur)',
                'uz': 'O&#x2BB;zbek (uz)',
                've': 'Tshiven&#x1E13;a (ve)',
                'vi': 'Ti&#x1EBF;ng Vi&#x1EC7;t (vi)',
                'vo': 'Volap&#xFC;k (vo)',
                'wa': 'Walon (wa)',
                'wo': 'Wollof (wo)',
                'xh': 'Xhosa (xh)',
                'yi': '&#x5D9;&#x5D9;&#x5B4;&#x5D3;&#x5D9;&#x5E9; (yi)',
                'yo': 'Yor&#xF9;b&#xE1; (yo)',
                'za': 'Sa&#x26F; cue&#x14B;&#x185; (za)',
                'zh': '&#x4E2D;&#x6587; (zh)',
                'zu': 'Zulu (zu)',
                // Custom Imaginated Languages
                'ge': 'Georgian (ge)',
                'xr': 'Romani (xr)',
                'me': 'Montenegrin (me)',
                'aa': 'Montenegrin (aa)'
        } 
    }

    getLanguage(value) {
        for (let [key, langValue] of Object.entries(this.langs)) {
            if(value === key) {
                return langValue
            }
        }
    }
}
